import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import {collection, addDoc} from "firebase/firestore";
import { db } from './utils/firebase';

function App() {
  const [todo, setTodo] = useState("")
  const addTodo = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, "todos"),{
        item: todo
      })
      console.log("Document written with ID: ", docRef.id);
    } catch(e) {
      console.error("Error adding document: ", e);
    }
  }
  return (
    <div className="App">
      <input
        type="text"
        placeholder="What do you have to do today?"
        onChange={(e) => setTodo(e.target.value)}
      />
      <div className="btn-container">
        <button
          type="submit"
          className="btn"
          onClick={addTodo}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default App;
