// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCxWxunfropXmrx2S0S-Ti79XedoJSrkvI",
  authDomain: "ifsc-codes-a011b.firebaseapp.com",
  databaseURL: "https://ifsc-codes-a011b-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ifsc-codes-a011b",
  storageBucket: "ifsc-codes-a011b.appspot.com",
  messagingSenderId: "561576875216",
  appId: "1:561576875216:web:fc45220590caa0e7663ad5",
  measurementId: "G-P1GTBN6KE2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);